import React from 'react'
import { Flex, Item } from 'react-flex-ready'
import Container from 'components/common/Container'
import Card from 'components/common/Card'
import Readiness from 'components/icons/Readiness'
import Strategy from 'components/icons/Strategy'
import Security from 'components/icons/Security'
import Migration from 'components/icons/Migration'
import Consulting from 'components/icons/Consulting'
import Pipelines from 'components/icons/Pipelines'
import Development from 'components/icons/Development'
import Architecture from 'components/icons/Architecture'
import { Content, Icon, IconWrapper, Wrapper } from './styles'

export default () => {
  const services = [{
    'icon': <Readiness/>,
    'title': 'CLOUD READINESS',
    'subtitle': 'Enable transformation',
    'description': 'We can provide you with the necessary guidance, analysing and comparing options and their benefits.'
  }, {
    'icon': <Strategy/>,
    'title': 'CLOUD STRATEGY',
    'subtitle': 'Prepare for the future',
    'description': 'We can deliver cloud strategies that align with your business and are future proof.'
  }, {
    'icon': <Security/>,
    'title': 'CLOUD SECURITY',
    'subtitle': 'Protect your business',
    'description': 'Our security services identify the possible risks and create a plan to mitigate them.'
  }, {
    'icon': <Migration/>,
    'title': 'CLOUD MIGRATION',
    'subtitle': 'Speed up the process',
    'description': 'We can migrate your applications and users to a cloud platform of your choice.'
  }, {
    'icon': <Consulting/>,
    'title': 'BIG DATA CONSULTING',
    'subtitle': 'Provide knowledge & insight',
    'description': 'Help with big data infra- structure and analytics tools or products for your project.'
  }, {
    'icon': <Pipelines/>,
    'title': 'BIG DATA PIPELINES',
    'subtitle': 'Extract, transform & load',
    'description': 'We build pipelines that gather, process and store your data.'
  }, {
    'icon': <Development/>,
    'title': 'BIG DATA DEVELOPMENT',
    'subtitle': 'Build what you need',
    'description': 'Improve existing or build custom solutions to clean and process your data into something usable.'
  }, {
    'icon': <Architecture/>,
    'title': 'BIG DATA ARCHITECTURE',
    'subtitle': 'Based on your needs',
    'description': 'We review your current data architecture, make improvements and prepare for the future.'
  }]
  return (
    <Wrapper as={Container} id="services">
      <h1>Services</h1>
      <Flex col={4}>
        {services.map(({ icon, title, subtitle, description }) => (
            <Item
              key={title}
              col={3}
              colTablet={6}
              colMobile={12}
              marginBottom={30}
              gap={1}
              stretch
            >
              <Card>
                <IconWrapper>
                  <Icon>
                    {icon}
                  </Icon>
                </IconWrapper>
                <Content>
                  <h2>{title}</h2>
                  <h3>{subtitle}</h3>
                  <p>{description}</p>
                </Content>
              </Card>
            </Item>
          )
        )}
      </Flex>
    </Wrapper>
  )
};
