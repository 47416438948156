import React from 'react'
import { Flex, Item } from 'react-flex-ready'
import Container from 'components/common/Container'
import SimpleCard from 'components/common/SimpleCard'
import Colt from 'assets/logos/colt.png'
import Edf from 'assets/logos/edf.png'
import Getronics from 'assets/logos/getronics.png'
import Vanbreda from 'assets/logos/vanbreda.png'
import Xenit from 'assets/logos/xenit.png'

import { Wrapper } from './styles'

export default () => {
  const clients = [{
    'logo': Colt,
    'name': 'colt',
  }, {
    'logo': Edf,
    'title': 'edf luminus',
  }, {
    'logo': Getronics,
    'title': 'Getronics',
  }, {
    'logo': Vanbreda,
    'title': 'vanbreda',
  }, {
    'logo': Xenit,
    'title': 'xenit',
  }]
  return (
    <Wrapper as={Container} id="clients">
      <h1>Clients</h1>
      <Flex col={6}>
        {clients.map(({ logo, name }) => (
            <Item
              key={name}
              col={2}
              colTablet={6}
              colMobile={12}
              marginBottom={30}
              gap={1}
              stretch
            >
              <SimpleCard>
                <img src={logo} alt={name}/>
              </SimpleCard>
            </Item>
          )
        )}
      </Flex>
    </Wrapper>
  )
};
