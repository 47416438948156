import React from 'react'

export default ({ color = '#ffffff' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    clipRule="evenodd"
    viewBox="0 0 1000 1000"
  >
    <path
      fillRule="nonzero"
      fill={color}
      d="M21.169 19.754c.522-.79.831-1.735.831-2.754a5 5 0 10-5 5 4.977 4.977 0 002.755-.832L22.586 24 24 22.586l-2.831-2.832zM17 20c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm-4.89 2H5l2.599-3h2.696a7.004 7.004 0 001.815 3zM10 17H0V0h22v12.11a7.033 7.033 0 00-2-1.425V2H2v13h8.295A6.956 6.956 0 0010 17zm-4-4H4V7h2v6zm3 0H7V4h2v9zm3 0h-2V9h2v4z"
      transform="scale(41.6667)"
    ></path>
  </svg>
)