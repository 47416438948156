import React from "react";
import colors from "tokens/colors";

export default ({ color = colors.primary }) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 379 279"
    >
      <path
        fill="#E5F2FF"
        fillRule="nonzero"
        d="M106.1-146.3c6.8 25.4-29.5 82.3-20 131.8 9.5 49.4 64.8 91.5 60.3 98.8-4.5 7.2-68.8-20.2-105.8-32.8C3.6 38.8-6 41-44.1 55.6c-38 14.6-104.3 41.5-136.7 28.1-32.3-13.4-30.6-67-17.7-114.9 13-47.8 37.3-89.9 72.3-110.4 34.9-20.5 80.6-19.5 128.2-21.9 47.7-2.4 97.4-8.3 104.1 17.2z"
        transform="translate(-316.043 -135.237) translate(523.191 326.638)"
      ></path>
      <path
        fillRule="nonzero"
        d="M-6.442-42.687S-31.369-22.885-44.28-16.728l37.838 27.657S6.475 1.188 20.84-6.452L-6.442-42.687z"
        transform="translate(-316.043 -135.237) scale(-1 1) rotate(-41.337 271.38 851.458)"
      ></path>
      <path
        fill="#363636"
        fillRule="nonzero"
        d="M0-20.128C-3.135-9.74-7.915-.87-15.007 5.866l1.937 8.224-43.985-1.007 7.907-42.529L0-20.128z"
        transform="translate(-316.043 -135.237) translate(505.449 389.769)"
      ></path>
      <path
        fillRule="nonzero"
        d="M-6.442-42.687S-31.369-22.885-44.28-16.728l37.838 27.657S6.475 1.188 20.84-6.452L-6.442-42.687z"
        transform="translate(-316.043 -135.237) scale(-1 1) rotate(-41.337 271.38 851.458)"
      ></path>
      <path
        fill="#E9BFA8"
        fillRule="nonzero"
        d="M0 21.727S2.295 7.355 6.857 2.256c4.563-5.099 17.042-12.642 18.518-9.958C26.852-5.019 14.372 3.397 14.372 3.397s5.636-1.342 10.467 6.977c4.83 8.32-10.735 14.761-10.735 14.761l-3.087 6.978-12.345-7.783L0 21.727z"
        transform="translate(-316.043 -135.237) translate(614.195 199.984)"
      ></path>
      <path
        fill="#F5D6BC"
        fillRule="nonzero"
        d="M0-14.076s-6.956 7.073-5.938 9.095c1.016 2.021 4.85-1.156 4.85-1.156s-3.13 4.765-.704 5.992C.634 1.082 4.39-3.033 4.39-3.033S1.26 1.299 3.06 2.093c1.799.794 11.423-5.991 9.702-7.579-1.721-1.589-2.66-.29-2.66-.29s2.014-3.392 0-4.475c-2.193-1.179-3.287.938-3.287.938s1.644-2.598.314-3.609c-1.331-1.01-4.148.289-4.148.289s.924-4.33-2.981-1.443"
        transform="translate(-316.043 -135.237) translate(630.159 217.729)"
      ></path>
      <path
        fill="#F5D6BC"
        fillRule="nonzero"
        d="M0-24.178c-2.114-.604-6.408 3.791-6.408 3.791-1.689 2.172-2.034 6.502-2.102 8.499-.217 5.576-2.326 8.543-2.326 8.543L-4.571.604C.28-5.645-1.307-13.65-2.09-16.481.274-20.014 1.261-23.818 0-24.178"
        transform="translate(-316.043 -135.237) translate(623.703 227.659)"
      ></path>
      <path
        fill="#334650"
        fillRule="nonzero"
        d="M0-152.138s-20.934 55.747-54.202 60.69c-14.878 2.206-26.118 2.432-33.976 2.013-9.372-.482-13.977-1.899-14.315-1.996-.017 0-.017-.017-.017-.017s-9.13 17.005-3.22 41.433c5.893 24.411 8.051 29.516 8.051 29.516s-44.845 32.978-81.318 2.947c0 0-33.751-98.307-7.053-118.516l.016-.016c10.064-7.633 22.593-13.719 37.971-15.973 7.889-1.192 16.52-1.369 25.957-.242 0 0 7.246 2.045 19.033 6.36a442.08 442.08 0 0122.559 8.969c24.428 10.467 31.143 13.591 31.143 13.591s26.022-25.667 32.737-41.238c0 0 13.413 1.352 16.634 12.479"
        transform="translate(-316.043 -135.237) matrix(1 0 0 1 627.757 385.711)"
      ></path>
      <path
        fill="#33321B"
        fillRule="nonzero"
        d="M0 24.701C-14.412 21.046-21.996 8.083-25.957-3.672-18.067-4.863-9.437-5.04 0-3.913c0 0 7.246 2.045 19.033 6.36 1.385 10.29.886 27.294-19.033 22.254"
        transform="translate(-316.043 -135.237) translate(505.65 237.325)"
      ></path>
      <path
        fill="#33321B"
        fillRule="nonzero"
        d="M-8.471-26.03s-5.642 11.151-12.093 19.778C-13.189-.447-8.789 1.517-8.471 1.667c.012.011.023-.002.023-.002s3.049-15.92-.023-27.695"
        transform="translate(-316.043 -135.237) scale(-1 1) rotate(-41.156 122.831 836.228)"
      ></path>
      <path
        fill="#F2F4F9"
        fillRule="nonzero"
        d="M0 53.346l-35.946 5.218a1.946 1.946 0 01-2.205-1.647l-7.942-54.713A1.946 1.946 0 01-44.446 0l35.947-5.217a1.946 1.946 0 012.205 1.646l7.94 54.712A1.946 1.946 0 010 53.346"
        transform="translate(-316.043 -135.237) translate(507.519 272.622)"
      ></path>
      <path
        fill="#F5D6BC"
        fillRule="nonzero"
        d="M0 2.281L5.502.135s5.233-14.493 11.741-5.369c0 0 4.495-2.683 4.361-4.159-.134-1.476-.201-3.623-.201-3.623s9.109 1.968 7.071 14.671c-2.038 12.703-9.822 11.093-17.47 11.228l-9.527 3.756L0 2.281z"
        transform="translate(-316.043 -135.237) translate(481.362 288.859)"
      ></path>
      <path
        fill="#33321B"
        fillRule="nonzero"
        d="M0 34.828s-42.865 28.373-61.383 14.686a74.227 74.227 0 01-7.681-6.554l-5.089-8.132s4.252-39 30.95-59.209l.015-.016c8.85-3.976 18.097-2.528 18.573 5.67 1.132 19.529-15.571 31.537-17.638 40.4l38.421-7.778S2.415 20.529 0 34.828"
        transform="translate(-316.043 -135.237) translate(484.91 274.025)"
      ></path>
      <path
        fill="#63412D"
        fillRule="nonzero"
        d="M1474.79 319.813c10.79-4.012 17.05-10.521 17.05-10.521-11.59 1.984-29.67-2.105-43.46-6.134-5.93-2.572-12.36-4.738-19.34-6.416-115.84-27.837-144.57 38.613-144.57 38.613s-39.51 13.47-44 64.654c-4.49 51.185 0 88.9 0 88.9 1.09 2.958 26.92 117.59 29.27 146.77 0 0 70.14-140.387 77.78-165.531 7.63-25.143 40.9-21.474 40.9-21.474s-30.32 11.499 86.42 6.112l.72 78.42s11.85-46.991 36.1-48.787c0 0 23.2-111.702-36.87-164.606z"
        transform="translate(-316.043 -135.237) matrix(.19474 0 0 .19474 247.628 106.5)"
      ></path>
      <path
        fill="#E9BFA8"
        fillRule="nonzero"
        d="M0-1.615S9.477 21.74 24.371 17.916c5.066-1.302 5.096-6.744 4.459-10.327-.846-4.675-3.712-9.799-1.399-12.619L11.9-20.042l-3.421-3.313S7.012-13.204 0-1.615"
        transform="translate(-316.043 -135.237) translate(489.935 235.92)"
      ></path>
      <path
        fill="#E9BFA8"
        fillRule="nonzero"
        d="M-8.516-2.694c1.721 4.428 1.955 10.295 5.4 11.491l-5.4 20.914c-1.432-.785-2.918-2.583-4.426-5.714C-20.915 7.506-13.384.14-8.516-2.694"
        transform="translate(-316.043 -135.237) scale(1 -1) rotate(31.497 682.107 811.804)"
      ></path>
      <path
        fill="#F5D6BC"
        fillRule="nonzero"
        d="M0-47.641s1.168 7.808.63 15.905c-.618 9.287-3.282 19.3-9.188 24.206-9.608 7.983-26.088-7.444-27.522-16.36 0 0-5.367-2.679-5.694-8.617-.328-5.938 6.161-5.19 8.68 3.998 0 0 4.908-1.308 3.597-13.143 3.62 1.988 4.268-3.764 7.208-7.59 0 0 7.774 2.331 13.162 1.148 0 0 1.786 2.239 6.613-1.288"
        transform="translate(-316.043 -135.237) translate(534.733 241.968)"
      ></path>
      <path
        fill="#9ABCED"
        fillRule="nonzero"
        d="M0 77.091h-54.95a3.824 3.824 0 01-3.824-3.824V3.824A3.824 3.824 0 01-54.95 0H0a3.825 3.825 0 013.825 3.824v69.443A3.825 3.825 0 010 77.091"
        opacity="0.6"
        transform="translate(-316.043 -135.237) translate(685.19 164.115)"
      ></path>
      <path
        fill="#4F7EB7"
        fillRule="nonzero"
        d="M0-75.481a3.024 3.024 0 00-3.02 3.02v69.442A3.024 3.024 0 000 0h54.95a3.023 3.023 0 003.019-3.019v-69.442a3.023 3.023 0 00-3.019-3.02H0zM54.95 1.61H0a4.634 4.634 0 01-4.63-4.629v-69.442A4.635 4.635 0 010-77.091h54.95a4.634 4.634 0 014.629 4.63v69.442A4.634 4.634 0 0154.95 1.61"
        transform="translate(-316.043 -135.237) translate(635.071 236.009)"
      ></path>
      <use
        width="45.758"
        height="60.855"
        x="319.534"
        y="36.816"
        transform="translate(-316.043 -135.237) translate(316.043 135.237) scale(.99474 .99762)"
        xlinkHref="#_Image1"
      ></use>
      <path
        fill="#9ABCED"
        fillRule="nonzero"
        d="M0 80.11h-100.642a6.642 6.642 0 01-6.642-6.642V6.642A6.641 6.641 0 01-100.642 0H0a6.642 6.642 0 016.642 6.642v66.826A6.642 6.642 0 010 80.11"
        opacity="0.6"
        transform="translate(-316.043 -135.237) translate(483.699 139.732)"
      ></path>
      <path
        fill="#4F7EB7"
        fillRule="nonzero"
        d="M0-78.5a5.844 5.844 0 00-5.838 5.837v66.826A5.844 5.844 0 000 0h100.642a5.844 5.844 0 005.837-5.837v-66.826a5.844 5.844 0 00-5.837-5.837H0zM100.642 1.61H0c-4.106 0-7.447-3.34-7.447-7.447v-66.826c0-4.107 3.341-7.447 7.447-7.447h100.642c4.105 0 7.447 3.34 7.447 7.447v66.826c0 4.107-3.342 7.447-7.447 7.447"
        transform="translate(-316.043 -135.237) translate(386.547 215.347)"
      ></path>
      <path
        fill="#9ABCED"
        fillRule="nonzero"
        d="M0 5.837h-55.756a2.919 2.919 0 010-5.837H0a2.919 2.919 0 010 5.837"
        opacity="0.5"
        transform="translate(-316.043 -135.237) translate(446.462 150.198)"
      ></path>
      <path
        fill="#7CA6D8"
        fillRule="nonzero"
        d="M0 32.607h-57.467a2.062 2.062 0 01-2.062-2.063V2.063c0-1.14.923-2.063 2.062-2.063H0c1.139 0 2.063.923 2.063 2.063v28.481c0 1.14-.924 2.063-2.063 2.063"
        opacity="0.71"
        transform="translate(-316.043 -135.237) translate(447.317 166.301)"
      ></path>
      <path
        fill="#9ABCED"
        fillRule="nonzero"
        d="M0 5.837h-16.505a2.919 2.919 0 110-5.837H0a2.92 2.92 0 010 5.837"
        opacity="0.5"
        transform="translate(-316.043 -135.237) translate(476.05 150.198)"
      ></path>
      <path
        fill="#9ABCED"
        fillRule="nonzero"
        d="M0 3.624h-17.344a1.811 1.811 0 010-3.624H0a1.813 1.813 0 010 3.624"
        opacity="0.5"
        transform="translate(-316.043 -135.237) translate(477.157 167.91)"
      ></path>
      <path
        fill="#9ABCED"
        fillRule="nonzero"
        d="M0 3.623h-17.344a1.81 1.81 0 110-3.623H0a1.812 1.812 0 010 3.623"
        opacity="0.5"
        transform="translate(-316.043 -135.237) translate(477.157 176.096)"
      ></path>
      <path
        fill="#9ABCED"
        fillRule="nonzero"
        d="M0 3.623h-17.344a1.81 1.81 0 110-3.623H0a1.812 1.812 0 010 3.623"
        opacity="0.5"
        transform="translate(-316.043 -135.237) translate(477.157 184.282)"
      ></path>
      <path
        fill="#9ABCED"
        fillRule="nonzero"
        d="M0 3.623h-17.344a1.81 1.81 0 110-3.623H0a1.812 1.812 0 010 3.623"
        opacity="0.5"
        transform="translate(-316.043 -135.237) translate(477.157 192.467)"
      ></path>
      <path
        fill="#9ABCED"
        fillRule="nonzero"
        d="M0 4.831h-64.209a2.416 2.416 0 010-4.831H0a2.416 2.416 0 110 4.831"
        opacity="0.5"
        transform="translate(-316.043 -135.237) translate(465.382 206.825)"
      ></path>
      <defs>
        <image
          id="_Image1"
          width="46"
          height="61"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAA9CAYAAADS+1sCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAFSklEQVRoge2aW28cxxFGT1X33Ha5vIgrUYDfDSS/IQjgnx6/GPGjAzsXI4ASOYBih6RI7WVu3ZWHJSky1C53yNmhDOQ87GIG0zXfdNdUV1ePfP3nGX3Q/vCDxfmc5MsvkYMD6cXoBnwfRmw+t/bHv2LBCG/f4l6/Nv+b36JHhzt7AO3DSPP991iwm+Pw7h3V13/ALi5sQ7Mn8ckeN8PKOlC1gappqZqIc0LuHVniyFOPdyIA8fTUwk8/3TcSAtU335B99ZVJlvXe8/eEV02wXy6WVE28c75poazC6kDgeC+3g1FC86fv1hq3xYLm2z+S/u73htCr+BvhZtj5rOJ8XsFDA2xw+qHk8h9vODw7I9mgKfzyH2w2QyZ7fWkGbvn42YeS89kWoq+JkcWPf+HfTSA+dOn7s8crXIMClHWw9/O6U8P4rzdYVdICZ23YfO35+aMFrkOjmf38ftmtVVMT/vn3m8OZGQtbP1Tx8vKx+taiizLQhIcG+y7hzd+wcLeXLzfYcIeHjxK3Ca2azcP8v9j8A+Hd/fBXbehxmb7sLOwhfNm2nRrYcoF79QVWl1hVYXUJbYsBDXYvwogKbjrtUfIKX9fd3ESnJzA9uXsyhtVDZEZqAVsuoVxiZQlJAt73PwE5J8T2iTOzOqQYkb4Y4bL+RX7yllniejPWp62H6E144hRVGaS3AbRI+xGe92RnWzRLnOyP0icZcSIcT/KeJG2HAhzvZyTu8an59KDAueHcBK6Eq4i8OiwQAaxbeNwfpewVw0SS23jMiOUcmV/Iq1DaaaXUWqDZaGNDJ8L0IGevSAYXDeDLn99CaFYHgpzk0RZOWDilauO9NNc5ZZQ6jif54O5xG38t+gZROTo+5oX3mJnVTaRqA94pmXfPKvY2d5ZuhpAdvUT86rSISJY6soFD3TZ8FC5CdnSC5uNnlLM9V12rpEcnaL75hfyc8IiSvniNZsVza+mEz15+gfinzZzPgf4aRUNPJbjnwAPEiJVNpAlG5pU8EbpWnsywsjGqJrJh+fkknAp5KqRe8IBdLALXi/SmjbRR2Mu7DcaHZaR+6krqAUI06tbYKxStq5q2abDQcD2/V41htnVNixixXYu+TVlHfJyfYu1VLh09kqxiedd5XYSducin7qVeoji58pO4+u/q46pIOmBmmye6ejn3fUkdPa3mZDIjjQbtCDqEykmhpN6u3Gw3XX/9cnon4gEEJNOWjBm0EFuweonbPwHd+iWVLBGygdLztarMIli38tyQrBUuIqCfXzp7zXo/8BnWVJ3XoEOxdrvQmpLQlKCKn3Ty9UF4UI0MN6904uPmFVgwxa9iuqx+BB0ddultq1vbYa7CzTalx4zSUlsGjyGoGGNXW6omOj5Cku0qVFVjNi8jcccj5B02KRzeRG3ZJjeJSTRhEVKK/Qn4ZFt7Nq92LxqgDatcRZuo97IpyyZETbaW0Qa7zhYGoWoNTTQgV9ItRjQbX5WMt89VvBOeUHrsTOYFJUbJrcRCg8vGeO8Yd8zFARlnOoj4xAtFqvhQ1STqZHp8bLgM5x63554mImnirA22M193KrgrT/Caj0kPpuB6yUvFu2GSLJ++eD3Ijfrm85rHO/B/4UPzqxV+nWRZ3RpNa+SJPjoktsGsanYZDlcLZVVWa87LxcdizrIOjDO1Iuu28zAro5X17pOVZR2YFGoawv1iznL1IdnWKsywqhkmcTeDsjY0fCJ5NutW3IlxuGIQrEpxmnq5t05IvSAdCkLOIQN+f0CeKgrIfrGq0DqFUSaMs+7BZlI4ilTY5Z6cUxjnSpEI/wW5SDtno+xPIAAAAABJRU5ErkJggg=="
        ></image>
      </defs>
    </svg>
);
