import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10rem;
`;

export const Content = styled.div`
  padding: 1rem 0;
  text-align: center;

  p {
    font-size: 14pt;
    font-weight: normal;
    line-height: 1.3;
    color: #ffffff;
  }

  h2 {
    padding: 0 1rem;
    color: #ffffff;
  }

  h3 {
    color: #ffffff;
    font-weight: normal;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: auto;
  height: auto;
  position: relative;
  text-align: center;
  margin: 1rem;
`;

export const Icon = styled.div`
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 30%;
  border: 1rem solid #ffffff;
  border-radius: 50%;
  padding: 1.5rem;
  margin: 1.5rem;

  @media (max-width: 960px) {
    width: 40%;
    margin-bottom: 2rem;
    border: 0.5rem solid #ffffff;
  }

  img {
    width: 100%;
  }
`;
