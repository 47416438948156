import React from 'react'
import Container from 'components/common/Container'
import GoalIllustration from 'components/illustrations/GoalIllustration'
import { Details, GoalWrapper, Thumbnail, Wrapper } from './styles'

export default () => {
  return (
    <Wrapper id="goal">
      <GoalWrapper as={Container}>
        <Thumbnail>
          <GoalIllustration/>
        </Thumbnail>
        <Details>
          <h1>Our goal!</h1>
          <p>
            We strive to offer our clients an extremely high level of service. We focus on designing, monitoring and
            optimising your cloud infrastructure and running applications.
          </p>
          <p>
            Using our experience, we plan, develop and deploy IT infrastructures for enterprise level customers. Using
            best practices in line with evolving challenges, demands, frameworks and protocols.
          </p>
        </Details>
      </GoalWrapper>
    </Wrapper>
  )
};
