import React from 'react'

export default ({ color = '#ffffff' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    clipRule="evenodd"
    viewBox="0 0 1000 1000"
  >
    <path
      fillRule="nonzero"
      fill={color}
      d="M12 2c3.453 0 5.891 2.797 5.567 6.78C19.312 8.734 22 9.531 22 12.5c0 1.93-1.57 3.5-3.5 3.5h-13C3.57 16 2 14.43 2 12.5c0-2.797 2.479-3.833 4.433-3.72C6.266 4.562 8.641 2 12 2zm0-2C7.994 0 4.733 3.141 4.521 7.092A5.499 5.499 0 005.5 18h13a5.499 5.499 0 00.979-10.908C19.267 3.141 16.006 0 12 0zM8 24v-2h3v-2h2v2h3v2H8zm9-2v2h3v-2h-3zM7 22H4v2h3v-2z"
      transform="scale(41.6667)"
    ></path>
  </svg>
)