import React from 'react'
import Header from 'components/theme/Header'
import Container from 'components/common/Container'
import Button from 'components/common/Button'
import BannerIllustration from 'components/illustrations/BannerIllustration'
import { BannerLogo, Details, IntroWrapper, Wrapper } from './styles'

export default () => {

  return (
    <Wrapper>
      <Header/>
      <IntroWrapper as={Container}>
        <Details>
          <h1>MULTI-CLOUD EXPERTISE</h1>
          <h4><b>ARCHITECTURE</b>, <b>DESIGN</b> and <b>IMPLEMENTATION</b> of
            your <b>APPLICATIONS</b>, <b>AI</b>, <b>ML</b> and <b>DATA</b> pipelines using <b>CLOUD</b> products and
            services.</h4>
          <a href="/contact">
            <Button>
              CONTACT US
            </Button>
          </a>
        </Details>
        <BannerLogo>
          <BannerIllustration/>
        </BannerLogo>
      </IntroWrapper>
    </Wrapper>
  )
};
