import styled from "styled-components";

const Card = styled.div`
  padding: 1rem;
  background: #0099ff;
  width: 100%;
  overflow: hidden;
  border-radius: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

export default Card;
