import React from 'react'

export default ({ color = '#ffffff' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    clipRule="evenodd"
    viewBox="0 0 1000 1000"
  >
    <path
      fillRule="nonzero"
      fill={color}
      d="M6.57 13.41c-.373 0-.741-.066-1.093-.195l.407-1.105a1.984 1.984 0 001.44-.026l.447 1.09a3.143 3.143 0 01-1.201.236zm8.67-.783l-1.659-.945.583-1.024 1.66.945-.584 1.024zm-6.455-.02l-.605-1.011 1.639-.981.605 1.011-1.639.981zm3.918-1.408a1.981 1.981 0 00-1.438-.034l-.401-1.108a3.137 3.137 0 012.292.055l-.453 1.087zm-8.734-.163a.968.968 0 10-.001 1.937.968.968 0 00.001-1.937zM17.545 4L12 0 6.455 4 0 0v20l6.455 4L12 20l5.545 4L24 20V0l-6.455 4zM22 18.887l-4 2.479V17h-1v4.141l-4-2.885V14h-2v4.255L7 21.14V16H6v5.365l-4-2.479V3.592l4 2.479V9h1V6.073l4-2.886V7h2V3.187l4 2.886V8h1V6.071l4-2.479v15.295zm-1.328-4.871l-1.296-1.274 1.273-1.293-.708-.702-1.272 1.295-1.294-1.272-.703.702 1.296 1.276-1.273 1.296.703.703 1.277-1.298 1.295 1.275.702-.708z"
      transform="scale(41.6667)"
    ></path>
  </svg>
)