import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/common/Layout/index.jsx";
import SEO from 'components/common/SEO';
import Intro from 'components/landing/Intro';
import Goal from 'components/landing/Goal';
import Ready from 'components/landing/Ready';
import Services from 'components/landing/Services';
import Clients from 'components/landing/Clients';
import Footer from 'components/theme/Footer';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO mdxType="SEO" />
    <Intro mdxType="Intro" />
    <Goal mdxType="Goal" />
    <Ready mdxType="Ready" />
    <Services mdxType="Services" />
    <Clients mdxType="Clients" />
    <Footer mdxType="Footer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      