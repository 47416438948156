import React from 'react'

export default ({ color = '#ffffff' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    clipRule="evenodd"
    viewBox="0 0 1000 1000"
  >
    <path
      fillRule="nonzero"
      fill={color}
      d="M12 4.942c1.827 1.105 3.474 1.6 5 1.833v7.76c0 1.606-.415 1.935-5 4.76V4.942zM21 3v11.535c0 4.603-3.203 5.804-9 9.465-5.797-3.661-9-4.862-9-9.465V3c3.516 0 5.629-.134 9-3 3.371 2.866 5.484 3 9 3zm-2 1.96c-2.446-.124-4.5-.611-7-2.416C9.5 4.349 7.446 4.836 5 4.96v9.575c0 3.042 1.69 3.83 7 7.107 5.313-3.281 7-4.065 7-7.107V4.96z"
      transform="scale(41.6667)"
    ></path>
  </svg>
)