import React from 'react'

export default ({ color = '#ffffff' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    clipRule="evenodd"
    viewBox="0 0 1000 1000"
  >
    <path
      fillRule="nonzero"
      fill={color}
      d="M24 10.935v2.131l-8 3.947v-2.23L21.64 12 16 9.21V6.987l8 3.948zM8 14.783L2.36 12 8 9.21V6.987l-8 3.948v2.131l8 3.947v-2.23zM15.047 4h-2.078L8.958 20h2.073l4.016-16z"
      transform="scale(41.6667)"
    ></path>
  </svg>
)