import React from 'react'

export default ({ color = '#ffffff' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    clipRule="evenodd"
    viewBox="0 0 1000 1000"
  >
    <path
      fillRule="nonzero"
      fill={color}
      d="M13 12l-.688-4h-.609L11 12a1.99 1.99 0 00-1 1.723 2 2 0 004 0A1.99 1.99 0 0013 12zm-1-8C6.478 4 2 8.477 2 14s4.478 10 10 10 10-4.477 10-10S17.522 4 12 4zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zM10 2.181V0h4v2.181c-1.438-.243-2.592-.238-4 0zm9.179 2.226L20.586 3 22 4.414l-1.321 1.321a12.134 12.134 0 00-1.5-1.328z"
      transform="scale(41.6667)"
    ></path>
  </svg>
)