import React from 'react'

export default ({ color = '#ffffff' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    clipRule="evenodd"
    viewBox="0 0 1000 1000"
  >
    <path
      fill={color}
      d="M24 11.374c0 4.55-3.783 6.96-7.146 6.796a8.66 8.66 0 00.384 3.641L13.518 23c-.338-1.129-.993-3.822-2.752-5.279-2.728.802-4.969-.646-5.784-2.627C2.149 15.14 0 13.258 0 10.541 0 6.342 4.604 1 11.99 1 19.522 1 24 6.377 24 11.374zM2.008 10.305c-.145 2.352 2.179 3.07 4.44 2.826.336 2.429 2.806 3.279 4.652 2.396 1.551.74 2.747 2.37 3.729 4.967l.002.006.111-.036c-.219-1.579-.09-3.324.36-4.528 3.907.686 6.849-1.153 6.69-4.828-.166-3.829-3.657-8.011-9.843-8.109-6.302-.041-9.957 4.255-10.141 7.306zm8.165-2.484a2 2 0 112.686-1.083c1.215.911 2.405 1.855 3.6 2.794A2.5 2.5 0 1118 14a2.502 2.502 0 01-2.426-1.895c-1.201.098-2.404.173-3.606.254a2 2 0 11-2.178-2.348c.12-.731.252-1.46.383-2.19zm2.059-.246c-.296.232-.66.383-1.057.417l-.363 2.18c.504.224.898.651 1.079 1.177l3.648-.289a2.46 2.46 0 01.262-.749l-3.569-2.736z"
      transform="scale(41.6667)"
    ></path>
  </svg>
)