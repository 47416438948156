import styled from "styled-components";

export const Wrapper = styled.div`
  padding-bottom: 4rem;
  background: rgb(0, 153, 255);
  background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 1922 452' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cg transform='matrix(1,0,0,-1,0,448.178)'%3E%3Cg transform='matrix(1.00054,0,0,-1.08413,2.27965e-13,448)'%3E%3Crect x='0' y='0' width='1920.98' height='448.1' style='fill:white;'/%3E%3C/g%3E%3Cg transform='matrix(1.0052,-6.373e-19,-6.373e-19,1,-0.791,0)'%3E%3Cpath d='M154.607,203.032C154.607,203.032 275.227,182.304 617.326,62.799C896.795,-34.828 1241.97,70.606 1441.88,80.95C1653.8,91.915 1797.3,31 1920.8,45.3C1920.9,45.4 1920.5,448.5 1920.8,447.7L1124.2,434.7L152,448.1L154.607,203.032Z' style='fill:rgb(0,153,255);fill-opacity:0.4;fill-rule:nonzero;'/%3E%3C/g%3E%3Cg transform='matrix(1.0082,-1.00415e-18,-1.00415e-18,1,-7.75477,-2.54734e-48)'%3E%3Cpath d='M0,0.078C0,0.078 288.978,3.776 649.085,44.228C919.972,74.657 1076.64,73.595 1249.95,44.228C1434.68,12.926 1745.48,2.504 1922,3.3C1922.1,3.4 1921.7,406.5 1922,405.7L972.2,392.7L0,406.1L0,0.078Z' style='fill:rgb(0,153,255);fill-opacity:0.2;fill-rule:nonzero;'/%3E%3C/g%3E%3Cg transform='matrix(1.00874,6.65693e-19,6.65693e-19,1.01418,-7.75477,-0.232785)'%3E%3Cpath d='M0,26.66C96.5,3.66 417.449,17.537 662.149,87.237C1023.67,153.02 1154.29,106.174 1316.7,82.573C1527.59,51.929 1639.4,11.8 1877.8,111.3C1892.83,117.474 1907.23,125.076 1920.8,134C1921.2,135 1920.8,448.7 1920.8,447.4C1920.5,447.1 0.4,448 0.4,448C0.4,448 0.2,26.66 0,26.66Z' style='fill:rgb(0,153,255);fill-rule:nonzero;'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A"),
    linear-gradient(
      180deg,
      rgba(0, 153, 255, 1) 95%,
      rgba(255, 255, 255, 1) 95%
    );
  background-repeat: no-repeat;
  background-position: left bottom;
  margin-bottom: 10rem;
`;

export const IntroWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 36pt;
    color: #ffffff;

    @media (max-width: 680px) {
      font-size: 30pt;
    }
  }

  h4 {
    margin-bottom: 2.5rem;
    margin-right: 12.5rem;
    font-size: 14pt;
    font-weight: normal;
    color: #ffffff;

    @media (max-width: 680px) {
      font-size: 12pt;
      margin-right: 2.5rem;
    }
  }
`;

export const BannerLogo = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width: 100%;
  }
`;
