import React from 'react'

export default ({ color = '#ffffff' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    clipRule="evenodd"
    viewBox="0 0 1000 1000"
  >
    <path
      fillRule="nonzero"
      fill={color}
      d="M14 12L0 21V3l14 9zm-4-9v4l8.022 5L10 17v4l14-9-14-9z"
      transform="scale(41.6667)"
    ></path>
  </svg>
)