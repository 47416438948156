import React from 'react'
import Container from 'components/common/Container'
import ReadyIllustration from 'components/illustrations/ReadyIllustration'
import { Details, ReadyWrapper, Thumbnail, Wrapper } from './styles'

export default () => {
  return (
    <Wrapper id="ready">
      <ReadyWrapper as={Container}>
        <Details>
          <h1>Are you ready?</h1>
          <p>
            What is the best way to go forward? Which apps should I move to cloud? How can I optimise the new
            opportunities?
          </p>
          <p>
            We can work with you from assessment to strategy definition and execution – help you to setup blueprints and
            orchestration or automation layers. Compare different cloud platforms, covering compute, storage,
            networking, unified communications and security.
          </p>
          <p>
            With significant experience of successful cloud migrations we are ready to help you.
          </p>
        </Details>
        <Thumbnail>
          <ReadyIllustration/>
        </Thumbnail>
      </ReadyWrapper>
    </Wrapper>
  )
};
